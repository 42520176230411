import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { tagManagerId } from '../shared/urls';
import Table from '../components/Table';
import BarChart from '../components/BarChartTwo';
import { Row, Col, Dropdown } from 'react-bootstrap';
import {
  fetchAllWashingtonData,
  fetchAllWashingtonCounties,
  fetchSelectedCounty,
  fetchCompany
} from '../redux/actionCreators/WashingtonActionCreator';

const mapStateToProps = state => {
  return {
    allWAData: state.washington.washingtonData,
    countyList: state.washington.washingtonCounties,
    isLoading: state.washington.isLoading,
    countyCompaniesData: state.washington.countyCompaniesData,
    companyInfo: state.washington.waCompanyInfo
  };
};

const mapDispatchToProps = {
  fetchAllWashingtonData: () => fetchAllWashingtonData(),
  fetchAllWashingtonCounties: () => fetchAllWashingtonCounties(),
  fetchSelectedCounty: newCountyValue => fetchSelectedCounty(newCountyValue),
  fetchCompany: newCompanyValueId => fetchCompany(newCompanyValueId)
};

const tagManagerArgs = {
  gtmId: tagManagerId
};

TagManager.initialize(tagManagerArgs);

const Products = props => {
  const {
    fetchAllWashingtonData,
    fetchAllWashingtonCounties,
    countyCompaniesData,
    countyList,
    companyInfo,
    allWAData
  } = props;

  const dispatch = useDispatch();

  const [countyDropDownOpen, setCountyDropDownOpen] = useState(false);
  const [companyDropDownOpen, setCompanyDropDownOpen] = useState(false);

  useEffect(() => {
    fetchAllWashingtonData();
    fetchAllWashingtonCounties();
  }, [fetchAllWashingtonData, fetchAllWashingtonCounties]);

  const onCountySelect = async newCountyValue => {
    window.dataLayer.push({
      event: 'Products County Select'
    });
    dispatch(fetchSelectedCounty(newCountyValue.county));
  };

  const onCompanySelect = async newCompanyValue => {
    window.dataLayer.push({
      event: 'Products Company Select'
    });
    dispatch(fetchCompany(newCompanyValue.id));
  };

  return (
    <div className='App'>
      <div>
        <Helmet>
          <title>Budding Trends: Data Analytics Tools for Your Company</title>
          <meta
            name='description'
            content='We will build custom data analytics tools for your company for free. Contact us on how we can do this for you.'
          />
        </Helmet>
      </div>
      <div>
        <p>
          <b>Dropdown Menu</b> - Select County then Company to see monthly sales
          data.
          <br />
          <b>Data Table</b> - Search company and click company table row.
        </p>
      </div>
      <div>
        <Row>
          <div className='county-dropdown'>
            <Col sm={12} md={3} lg={2}>
              <Dropdown direction='down' bg='dark' variant='dark'>
                <Dropdown.Toggle
                  onToggle={e => setCountyDropDownOpen(!countyDropDownOpen)}
                  id='county-dropdown'
                  bg='dark'
                  variant='dark'
                >
                  Select County
                </Dropdown.Toggle>
                <Dropdown.Menu direction='down' bg='dark' variant='dark'>
                  {countyList.map((eachCounty, index) => (
                    <div key={index}>
                      <Dropdown.Item onClick={() => onCountySelect(eachCounty)}>
                        {eachCounty.county}
                      </Dropdown.Item>
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </div>
          <div className='company-dropdown'>
            <Col sm={12} md={3} lg={2}>
              <Dropdown direction='down' bg='dark' variant='dark'>
                <Dropdown.Toggle
                  onToggle={e => setCompanyDropDownOpen(!companyDropDownOpen)}
                  id='company-dropdown'
                  bg='dark'
                  variant='dark'
                >
                  Select Company
                </Dropdown.Toggle>
                <Dropdown.Menu direction='down' bg='dark' variant='dark'>
                  {countyCompaniesData.map((eachCompany, index) => (
                    <div key={index}>
                      <Dropdown.Item
                        onClick={() => onCompanySelect(eachCompany)}
                      >
                        {eachCompany.name}
                      </Dropdown.Item>
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </div>
        </Row>
      </div>
      <div>
        <br />
        <BarChart companyInfo={companyInfo} />
        <br />
        <p>
          <b>Note:</b> Retail store is only listed if it has reported sales
        </p>
      </div>
      <div>
        <Table allWAData={allWAData} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);

/*




import DataTable from '../components/dataTable';

    dataTable = () => {
        return this.state.countyComaniesData.map((data, i) => {
            return <DataTable obj={data} key={i} />;
        });
    }

<div className="wrapper-users">
                    <div className="container">
                        <table className="table table-striped table-dark">
                            <thead className="thead-dark">
                                <tr>
                                    <td>License</td>
                                    <td>County</td>
                                    <td>Total Sales</td>
                                    <td>Total Taxes</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.dataTable()}
                            </tbody>
                        </table>
                    </div>
                </div>



                <div className="wrapper-users">
                    <div className="container">
                        <table className="table table-striped table-dark">
                            <thead className="thead-dark">
                                <tr>
                                    <td>Sales</td>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {this.dataTable()}
                            </tbody>
                        </table>
                    </div>
                </div>
*/
