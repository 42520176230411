import React from 'react';
import { Container } from 'react-bootstrap';
import winkiemoji from '../assets/winki-face.png';
import styled from 'styled-components';

const Styles = styled.div`
  h2, h3, h4, h5 {
    font-family: Helvetica;
  },
  .linkin {
    a:link { color:#244f23;  }
    a:visited { color:#244f23;  }
    a:hover { color:#244f23; }
    a:active { color:#244f23; }
  }
`;

const ReturnContact = () => {
  	return (
    	<Container fluid="sm">
        <Styles>
          <div className="linkin">
      		    <h2 className="bold">Thank you for contacting Budding Trends!</h2>
              <h4>We will get back to you shortly...unless you're a solicitor <img src={winkiemoji} alt="winki emoji" width="25" /></h4><br /><br />
              <h5><a href="/">Back to Home</a></h5>
              <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
          </div>
        </Styles>
      </Container>
  	);
}

export default ReturnContact;