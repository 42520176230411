import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  h2, h3, h4, h5 {
    font-family: Helvetica;
  },
  .linkin {
    a:link { color:#244f23;  }
    a:visited { color:#244f23;  }
    a:hover { color:#244f23; }
    a:active { color:#244f23; }
  }
`;

const NoMatch = () => {
  return (
    <div className="App">
       <Styles>
          <div className="linkin">
            <h3>Sorry! We can't find this page</h3><br />
            <h5><a href="/">Back to Home!</a></h5>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
          </div>
      </Styles>
    </div>
  );
}

export default NoMatch;