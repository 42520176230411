import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
/*import ReactGA from "react-ga";
import { createBrowserHistory } from "history";*/
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { ConfigureStore } from './redux/configureStore';
import { tagManagerId } from './shared/urls';
import Home from './pages/Home';
import Contact from './pages/Contact';
import ReturnContact from './pages/ReturnContact';
import Products from './pages/Products';
import NoMatch from './pages/NoMatch';
import Layout from './components/Layout';
import NavigationBar from './components/NavigationBar';
import Jumbotron from './components/Jumbotron';
import Footer from './components/Footer';

const store = ConfigureStore();
//const history = createBrowserHistory();

const tagManagerArgs = {
  gtmId: tagManagerId,
  dataLayerName: 'PageDataLayer'
};

TagManager.initialize(tagManagerArgs);

function App() {
  window.dataLayer.push({
    event: 'App'
  });

  /*useEffect(() => {
    ReactGA.initialize(measureID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(() => {
   console.log(window.location.pathname)
  })*/

  return (
    <Provider store={store}>
      <React.Fragment>
        <Router>
          <NavigationBar />
          <Jumbotron />
          <Layout>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/products' component={Products} />
              <Route path='/contact' component={Contact} />
              <Route path='/returncontact' component={ReturnContact} />
              <Route component={NoMatch} />
            </Switch>
          </Layout>
          <Footer />
        </Router>
      </React.Fragment>
    </Provider>
  );
}

export default App;
