import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import barChartPic from '../assets/have-a-heart.png';
import tablePic from '../assets/table1.png';
import styled from 'styled-components';

const Styles = styled.div`
  h3 {
    font-family: Helvetica;
  }
  ,
  h5,
  h6 {
    font-family: Arial;
  }
  .linkin {
    a:link {
      color: #244f23;
    }
    a:visited {
      color: #244f23;
    }
    a:hover {
      color: #244f23;
    }
    a:active {
      color: #244f23;
    }
  }
`;

const Home = () => {
  return (
    <Container fluid='sm'>
      <Row xs={1} lg={2}>
        <Col sm={12} lg={4}>
          <div>
            <Helmet>
              <title>
                Welcome to Budding Trends! Data Analytics for Your Company
              </title>
              <meta
                name='description'
                content='We will build custom data analytics tools for your company for free. Contact us on how we can do this for you.'
              />
            </Helmet>
          </div>
          <div className='mainContainer'>
            <Styles>
              <div className='linkin'>
                <h3>Welcome to Budding Trends</h3>
                <br />
                <h5>
                  We are currently in production. We will build custom data
                  analytics tools for your company for free!
                </h5>
                <br />
                <h6>Contact us on how we can do this for your company!</h6>
                <br />
                <p>
                  We are also <a href='https://www.metrc.com/'>Metrc</a>{' '}
                  certified
                </p>
              </div>
            </Styles>
          </div>
        </Col>
        <Col sm={12} lg={8}>
          <div className='mainContainer'>
            <img
              src={barChartPic}
              alt='Data Analytics Bar Chart'
              width='100%'
            />
          </div>
        </Col>

        <Col sm={12} lg={8}>
          <div className='mainContainer'>
            <img src={tablePic} alt='Data Analytics Table' width='100%' />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
