import React from "react";
import { Container } from "react-bootstrap";
import styled from 'styled-components';

// temp container to force footer to be on the bottom while new site coming banner is in place
const AppContainer = styled(Container)`
	min-height: 65.7vh;
`

function Layout(props) {
  return (
    <AppContainer>
      <div className="thisContainer">{props.children}</div>
    </AppContainer>
  );
}
export default Layout;

/*const Layout = (props) => (
  <Container>
    {props.children}
  </Container>
)
export default Layout;*/
