import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../shared/urls';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const Contact = () => {
  const [first_name, set_first_name] = useState('');
  const [last_name, set_last_name] = useState('');
  const [email, set_email] = useState('');
  const [text_area, set_text_area] = useState('');

  let history = useHistory();

  const isValidEmailAddress = email => {
    const pattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(email);
  };

  const onSubmit = e => {
    e.preventDefault();

    if (
      first_name.length < 2 ||
      last_name.length < 2 ||
      email.length < 7 ||
      text_area < 2
    ) {
      alert('Invalid input, please try again');
      return;
    } else if (!isValidEmailAddress(email)) {
      alert('Email format is wrong. Please try again');
      return;
    } else {
      const thisEmail = {
        first_name,
        last_name,
        email,
        text_area
      };

      console.log('E - ', e);
      const url = 'email';
      axios
        .post(baseUrl + url, thisEmail)
        .then(res => console.log('200 Response - ', res.json()))
        .catch(res => console.log('Error Response - ', res.data));
    }

    set_first_name('');
    set_last_name('');
    set_email('');
    set_text_area('');

    e.preventDefault();
    history.push('/returncontact');
  };

  return (
    <div className='App'>
      <div>
        <Helmet>
          <title>Thank you for contacting Budding Trends!</title>
          <meta
            name='description'
            content='We are excited to build data analytics tools for your company!'
          />
        </Helmet>
      </div>
      <div className='contactForm'>
        <h3>Contact Budding Trends</h3>
        <br />
        <p>
          <b>Solicitors:</b> Do not contact
        </p>
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Row>
              <Col>
                <Form.Control
                  placeholder='First name'
                  type='text'
                  className='form-control'
                  value={first_name}
                  name='first_name'
                  onChange={e => set_first_name(e.target.value)}
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder='Last name'
                  type='text'
                  className='form-control'
                  value={last_name}
                  name='last_name'
                  onChange={e => set_last_name(e.target.value)}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group controlId='formGroupEmail'>
            <Row>
              <Col>
                <Form.Control
                  type='email'
                  placeholder='Enter email'
                  className='form-control'
                  value={email}
                  name='email'
                  onChange={e => set_email(e.target.value)}
                />
              </Col>
              <Col></Col>
            </Row>
          </Form.Group>
          <Form.Group controlId='exampleForm.ControlTextarea1'>
            <Form.Label>Enter Message (1000 character limit)</Form.Label>
            <Form.Control
              as='textarea'
              rows='5'
              type='text'
              className='form-control'
              value={text_area}
              name='text_area'
              onChange={e => set_text_area(e.target.value)}
            />
          </Form.Group>
          <Button variant='dark' type='submit'>
            Submit
          </Button>
          <br />
          <br />
          <br />
        </Form>
      </div>
    </div>
  );
};

export default withRouter(Contact);
