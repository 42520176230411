import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  .footer-container {
    margin-top: 50px;
    height: 100px;
    position: "fixed";
    left: "0";
    bottom: "0";
    background-color: #222;
    color: #bbb;
    text-align: center;
    padding-top: 10px;
  },
  .footerCSS {
    font-family: Verdana;
    font-size: 16px;
  }
  .btn-social-icon {
    color: #244f23;
  }
`;

const Footer = () => (
    <Styles>
      <div className="footer-container">     
        <h5>Budding Trends &#169; {new Date().getFullYear()}</h5>
        <a className="btn btn-social-icon btn-instagram" href="https://www.instagram.com/buddingtrends/"><i className="fa fa-instagram" /></a>{' '}
        <a className="btn btn-social-icon btn-facebook" href="https://www.facebook.com/BuddingTrends/"><i className="fa fa-facebook" /></a>{' '}
        <a className="btn btn-social-icon btn-twitter" href="https://twitter.com/buddingtrends"><i className="fa fa-twitter" /></a>{' '}
        <a className="btn btn-social-icon btn-linkedin" href="https://www.linkedin.com/company/budding-trends/"><i className="fa fa-linkedin" /></a> 
      </div>
    </Styles>

  )
  
export default Footer;  
/*
                    <div className="col-4 col-sm-2 offset-1">
                        <p className="footerCSS">Budding Trends &#169;2020</p>
                    </div>
*/