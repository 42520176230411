export const WASHINGTON_ALL_COUNTIES = 'WASHINGTON_ALL_COUNTIES';
export const WASHINGTON_ALL_COUNTIES_FAILED = 'WASHINGTON_ALL_COUNTIES_FAILED';
export const WASHINGTON_ALL_COUNTIES_LOADING = 'WASHINGTON_ALL_COUNTIES_LOADING';

export const WASHINGTON_ALL_DATA_ADD = 'WASHINGTON_ALL_DATA_ADD';
export const WASHINGTON_ALL_DATA_FAILED = 'WASHINGTON_ALL_DATA_FAILED';
export const WASHINGTON_ALL_DATA_LOADING = 'WASHINGTON_ALL_DATA_LOADING';

export const WASHINGTON_ADD_SELECT_COUNTY = 'WASHINGTON_ADD_SELECT_COUNTY';
export const WASHINGTON_SELECT_COUNTY_FAILED = 'WASHINGTON_SELECT_COUNTY_FAILED';
export const WASHINGTON_SELECT_COUNTY_LOADING = 'WASHINGTON_SELECT_COUNTY_LOADING';

export const WASHINGTON_ADD_SELECT_COMPANY = 'WASHINGTON_ADD_SELECT_COMPANY';
export const WASHINGTON_SELECT_COMPANY_FAILED = 'WASHINGTON_SELECT_COMPANY_FAILED';
export const WASHINGTON_SELECT_COMPANY_LOADING = 'WASHINGTON_SELECT_COMPANY_LOADING';