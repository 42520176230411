import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import buddingTrendsLogo from '../assets/buddingTrendsLogo.jp2';

const Styles = styled.div`
  .jumbo {
    background-color: black;
    color: #efefef;
    height: 120px;
    position: relative;
    z-index: -2;
  }

  .overlay {
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  h1,
  h2 {
    font-family: Helvetica;
  }
  h2 {
    font-size: 18px;
  }
  @media screen and (max-width: 700px) {
    .jumbo {
      background-color: black;
      background-size: cover;
      height: 120px;
      position: relative;
      padding-top: 10px;
    }
  }
`;

class Jumbotron extends React.Component {
  render() {
    return (
      <Styles>
        <div className='jumbo'>
          <div className='overlay'></div>
          <Container className='companyTitle'>
            <div className='thisCompany'>
              <h1>
                Budding Trends{' '}
                <img
                  src={buddingTrendsLogo}
                  alt='Budding Trends Logo'
                  width='50'
                />
              </h1>
              <h2>Building Data Analytics Tools for Your Company</h2>
            </div>
          </Container>
        </div>
      </Styles>
    );
  }
}

export default Jumbotron;
